import styled from '@emotion/styled'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Separator } from 'app/components/Common/Separator'
import { Wave } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

import { Filter } from './Filter'
import { Gallery } from './Gallery'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  claim?: string
  languageCode: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  claim,
  languageCode,
}: Props) {
  if (!categories) {
    return null
  }

  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []
  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            disableScroll.off()
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <Separator />
      {claim ? <Claim>{claim}</Claim> : null}

      {categories.length > 1 ? (
        <Wrapper>
          <Wave />
          <Filters dial={5} row wrap>
            <Filter
              active={filteredCategory === 'all'}
              category="all"
              title={useVocabularyData('all', languageCode)}
              onClick={() => {
                setFilteredCategory('all')
              }}
            />
            {uniqBy(categories, 'title').map((item, index) => {
              const category = item.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()

              return category ? (
                <Filter
                  active={filteredCategory === category}
                  category={category}
                  key={index}
                  onClick={(e) => {
                    setFilteredCategory(e.target.getAttribute('data-category'))
                  }}
                  title={item.title}
                />
              ) : undefined
            })}
          </Filters>
        </Wrapper>
      ) : null}

      <Categories row wrap>
        {uniqBy(categories, 'title').map((list) => {
          const category = list.title
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()

          if (!list.thumbnails) {
            return null
          }

          return list.thumbnails.map((item, index) => {
            counter = counter + 1

            return (
              <Thumb
                className={
                  filteredCategory === category || filteredCategory === 'all'
                    ? 'visible'
                    : undefined
                }
                data-counter={counter}
                key={index}
                onClick={(e) => {
                  if (!modalGalleryStatus) {
                    setModalGalleryStatus(true)
                    disableScroll.on()
                  } else {
                    setModalGalleryStatus(false)
                    disableScroll.off()
                  }

                  const realClickedSlide =
                    Number(e.currentTarget.getAttribute('data-counter')) - 1

                  setClickedSlide(realClickedSlide)
                  setTimeout(() => {
                    setClickedSlide(null)
                  }, 100)
                }}
              >
                <Image {...item} />
              </Thumb>
            )
          })
        })}
      </Categories>

      <Modal className={modalGalleryStatus ? ' open' : undefined}>
        <ModalClose
          languageCode={languageCode}
          onClick={() => {
            disableScroll.off()
            setModalGalleryStatus(false)
            setClickedSlide(0)
          }}
          variant="right"
        />
        <Gallery
          clickedSlide={clickedSlide}
          images={images}
          isOpen={modalGalleryStatus}
        />
      </Modal>
    </Container>
  )
})

const Container = styled.section`
  margin: 11.875rem auto 7.5rem;
  padding: 0 5vw;

  @media (max-width: 1023px) {
    margin: 8.125rem auto 5.625rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    padding: 0 1.25rem;
  }
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.375rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Wrapper = styled.div`
  max-width: 67.25rem;
  margin: 6.4375rem auto 0;
  position: relative;

  > svg {
    width: 100%;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralLight3};
    opacity: 0.6;
    position: absolute;
    top: 60%;
    left: 0;
    transform: translateY(-50%);
    z-index: -1;
  }

  @media (max-width: 767px) {
    margin: 3.125rem auto 0;

    > svg {
      display: none;
    }
  }
`

const Filters = styled(FlexBox)``

const Categories = styled(FlexBox)`
  margin: 6rem -1.5rem 0 0;

  @media (max-width: 767px) {
    margin: 1.375rem -1rem 0 0;
  }
`

const Thumb = styled.div`
  display: none;
  width: calc(25% - 1.5rem);
  cursor: pointer;
  margin: 1.5rem 1.5rem 0 0;
  overflow: hidden;
  padding-bottom: calc(25% - 1.5rem);
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  &.visible {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: calc(33.333% - 1.5rem);
    padding-bottom: calc(33.333% - 1.5rem);
  }
  @media (max-width: 767px) {
    width: calc(50% - 1rem);
    padding-bottom: calc(50% - 1rem);
    margin: 1rem 1rem 0 0;
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
